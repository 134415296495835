import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/AuthLayout';
import HomeLayout from 'layouts/HomeLayout';
import FormLayout from 'layouts/FormLayout';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/external/companies" />,
  },
  {
    path: '/form',
    component: FormLayout,
    routes: [
      {
        path: '/form/:id',
        exact: true,
        component: lazy(() => import('pages/SiteForm')),
      }
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('pages/Login')),
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('pages/ForgotPassword')),
      },
      {
        path: '/auth/reset-password',
        component: lazy(() => import('pages/ResetPassword')),
      },
      {
        path: '/auth/send-verify-email',
        exact: true,
        component: lazy(() => import('pages/VerifyEmailSend')),
      },
      {
        path: '/auth/verification',
        component: lazy(() => import('pages/EmailVerification')),
      },
      {
        component: () => <Redirect to="/auth/login" />,
      },
    ],
  },
  {
    path: '*',
    component: HomeLayout,
    routes: [

      {
        path: '/users/create',
        exact: true,
        component: lazy(() => import('pages/User')),
      },
      {
        path: '/user',
        exact: true,
        component: lazy(() => import('pages/User')),
      },


      //Internal
      {
        path: '/internal',
        exact: true,
        component: () => <Redirect to="/internal/users" />,
      },
      {
        path: '/internal/users',
        exact: true,
        component: lazy(() => import('pages/InternalUsers')),
      },
      {
        path: '/internal/teams',
        exact: true,
        component: lazy(() => import('pages/Teams')),
      },
      {
        path: '/internal/admins',
        exact: true,
        component: lazy(() => import('pages/Admins')),
      },

      //External,
      {
        path: '/external',
        exact: true,
        component: () => <Redirect to="/external/companies" />,
      },
      {
        path: '/external/companies',
        exact: true,
        component: lazy(() => import('pages/Clients')),
      },
      {
        path: '/external/users',
        exact: true,
        component: lazy(() => import('pages/Users')),
      },
      {
        path: '/external/notifications',
        exact: true,
        component: lazy(() => import('pages/UserNotifications')),
      },
      {
        path: '/external/clients-comptes',
        exact: true,
        component: lazy(() => import('pages/ClientsOD')),
      },
      {
        path: '/external/clients-exclusion',
        exact: true,
        component: lazy(() => import('pages/ClientsExclusion')),
      },
      {
        path: '/external/clients-international',
        exact: true,
        component: lazy(() => import('pages/ClientsInternational')),
      },
      {
        path: '/external/monitoring',
        exact: true,
        component: lazy(() => import('pages/ClientsMonitoring')),
      },



      {
        path: '/teams/create',
        exact: true,
        component: lazy(() => import('pages/Team')),
      },

      {
        path: '/contacts',
        exact: true,
        component: lazy(() => import('pages/Leads')),
      },
      {
        path: '/clients/create',
        exact: true,
        component: lazy(() => import('pages/Client')),
      },
      {
        path: '/client',
        exact: true,
        component: lazy(() => import('pages/Client')),
      },
      {
        path: '/forms-list',
        exact: true,
        component: lazy(() => import('pages/Leads/sites')),
      },
      //catalogue
      {
        path: '/catalogues',
        exact: true,
        component: lazy(() => import('pages/Catalogue')),
      },
      {
        path: '/catalogues/:type',
        exact: true,
        component: lazy(() => import('pages/Catalogue')),
      },
      {
        path: '/recycles',
        exact: true,
        component: lazy(() => import('pages/Catalogue/recycles')),
      },

      {
        path: '/catalogues/catalogue/create',
        exact: true,
        component: lazy(() => import('pages/Catalogue/Details')),
      },
      {
        path: '/catalogues/catalogue/details',
        exact: true,
        component: lazy(() => import('pages/Catalogue/Details')),
      },
      //products
      {
        path: '/data',
        exact: true,
        component: lazy(() => import('pages/Products')),
      },
      {
        path: '/data/products',
        exact: true,
        component: lazy(() => import('pages/Products')),
      },
      {
        path: '/data/products/create',
        exact: true,
        component: lazy(() => import('pages/Products/Details')),
      },
      {
        path: '/data/product',
        exact: true,
        component: lazy(() => import('pages/Products/Details')),
      },


      //sessions
      {
        path: '/data/sessions',
        exact: true,
        component: lazy(() => import('pages/Sessions')),
      },
      //locations
      {
        path: '/locations',
        exact: true,
        component: lazy(() => import('pages/Locations')),
      },

      {
        path: '/data/inspection',
        exact: true,
        component: lazy(() => import('pages/Caracteristiques')),
      },
      {
        path: '/data/inspection/caracteristiques',
        exact: true,
        component: lazy(() => import('pages/Caracteristiques')),
      },
      {
        path: '/data/inspection/compteSel',
        exact: true,
        component: lazy(() => import('pages/CompteSel')),
      },
      {
        path: '/data/inspection/equipement',
        exact: true,
        component: lazy(() => import('pages/Equipement')),
      },
      {
        path: '/data/inspection/interventionSel',
        exact: true,
        component: lazy(() => import('pages/InterventionSel')),
      },
      {
        path: '/data/inspection/codeproducts',
        exact: true,
        component: lazy(() => import('pages/CodeProduct')),
      },

      //training sessions
      {
        path: '/data/training',
        exact: true,
        component: lazy(() => import('pages/Training')),
      },

      {
        path: '/logs',
        exact: true,
        component: lazy(() => import('pages/Logs')),
      },


      //xsell
      {
        path: '/xsell',
        exact: true,
        component: lazy(() => import('pages/Xsell/Users')),
      },
      {
        path: '/xsell/users',
        exact: true,
        component: lazy(() => import('pages/Xsell/Users')),
      },
      {
        path: '/xsell/submissions',
        exact: true,
        component: lazy(() => import('pages/Xsell/Submissions')),
      },


      //training
      {
        path: '/profile',
        exact: true,
        component: lazy(() => import('pages/ProfileSettings')),
      },

      {
        component: () => <Redirect to="/users" />,
      },
    ],
  },
];

export default routes;
