import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
    makeStyles,
    AppBar,
} from '@material-ui/core';
import { store } from 'react-notifications-component';
import useRouter from 'utils/useRouter';
import { TabPanel, StyledTabs, StyledTab } from 'components/CustomTab'

const useStyles = makeStyles(theme => ({
    appbar: {
        boxShadow: 'none',
        padding: theme.spacing(0, 5),
        backgroundColor: theme.palette.background.default,
    },
    tabsContainer: {
        color: theme.palette.app.primary
    },
    tabContainer: {
        maxWidth: 'unset'
    }
}));

const ClientsTab = ({ value }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const router = useRouter();
    const handleTabChange = (event, newValue) => {
        if (newValue == 0) {
            router.history.push("/external/companies")
        } else if (newValue == 1) {
            router.history.push("/external/clients-comptes")
        } else if (newValue == 2) {
            router.history.push("/external/clients-exclusion")
        } else if (newValue == 3) {
            router.history.push("/external/clients-international")
        }else if (newValue == 4) {
            router.history.push("/external/monitoring")
        }
    };
    return (
        <AppBar position="static" className={classes.appbar}>
            <StyledTabs value={value} onChange={handleTabChange} aria-label="services tabs" className={classes.tabsContainer}>
                <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.entreprises" />} />
                <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.entreprisesod" />} />
                <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.enterprisesexclusion" />} />
                <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.enterpriseinternational" />} />
                <StyledTab className={classes.tabContainer} label={<FormattedMessage id="navbar.monitoring" />} />
            </StyledTabs >
        </AppBar>
    );
};

export default ClientsTab;
